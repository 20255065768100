<template>
    <div class="cm_page">
        <div class="cm_main">
            <div class="cm_nav">
                <el-breadcrumb class="crumb" separator="/">
                    <el-breadcrumb-item :to="{ path: '/customManage' }">客户管理</el-breadcrumb-item>
                </el-breadcrumb>
                <el-button type="primary" @click="$router.push('/customAdd')">添加客户</el-button>
            </div>
            <div class="customs" v-loading="dataListLoading">
                <el-card v-for="item in list" :key="item.id" class="custom">
                    <div class="custom_main" @click="$router.push('/peizhiManage?customId='+item.id)">
                        <icon-svg class="custom_icon" name="custom"></icon-svg>
                        <div class="name">
                          <label>客户{{item.id}}：{{item.name}}</label>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <a>编辑</a>
                          <a>复制</a>
                          <a>删除</a>
                        </div>
                        <i class="el-icon-arrow-right"></i>
                    </div>
                </el-card>
            </div>
            
        </div>
        
    </div>
</template>
<script>
  export default {
    data () {
      return {
        dataListLoading: false,
        list: []
      }
    },
    methods: {
      getList() {
        this.dataListLoading = true
        this.$http({
          url: this.$http.adornUrl('/bus/buscustom/getList'),
          method: 'get'
        }).then(({data}) => {
          this.dataListLoading = false
          this.list = data.list
        })
      }
    },
    created() {
      this.getList()
    }
  }
</script>
<style lang="scss">
.customs{
  display: flex;
  flex-flow: wrap;
  margin: 0 -10px;
  .custom{
      width: 400px;
      margin: 10px;
      cursor: pointer;
      .custom_main{
          display: flex;
          align-items: center;
          .custom_icon{
              font-size: 30px;
              margin-right: 20px;
          }
          .name{
              flex: 1;
          }
      }
      
  }
}
</style>